import { useState } from 'react';
import { Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Enum from '../enum';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import axiosInstance from 'src/utils/axios';
import api from 'src/services/api';
import { useQueryCustom } from 'src/utils/reactQueryHooks';
import WaitingBox from 'src/components/WaitingBox';
import RegistrantForm from 'src/pages/RegistrantForms/components/Form';
import { toast } from 'react-toastify';

const Add = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryParams = useParams();
  const backUrl = Enum.routes.root(queryParams.type, queryParams.travel);
  // ------------------------------------------------------- query Get
  const getTravel = async () => axiosInstance.get(`${api.travel.base}/${queryParams.travel}`);

  const resTravel = useQueryCustom({
    name: `${api.travel.base}_get_${queryParams.travel}`,
    url: getTravel,
    params: { id: queryParams.travel },
  });

  const firstStep = resTravel?.data?.data?.forms?.[0];
  // ------------------------------------------------------- query Get

  const getById = async () => axiosInstance.get(`${api.form.base}/${firstStep?.id}`);

  const resQuery = useQueryCustom({
    name: `${api.form.base}_get_${firstStep?.id}`,
    url: getById,
    enabled: !!firstStep?.id,
    // onSuccess: (resData) => {
    //   console.log({ resData });
    //   const x = resData?.data?.form?.[0];
    //   if (x && !currentTab) setCurrentTab({ label: x?.name, value: x?.id, data: x });
    // },
  });
  const onSuccessMutating = () => {
    toast.success(t('successfully'));
    navigate(backUrl);
  };
  console.log({ queryParams, resTravel, resQuery });
  return (
    <Box
      sx={{
        width: '100%',
        // display: { xs: 'block', md: 'flex' },
        gap: 2,
        bgcolor: 'background.paper',
        borderRadius: 1,
        p: 4,
      }}
    >
      <HeaderBreadcrumbs back={`${backUrl}`} heading={t('addAuthor', { author: Enum.title.name[0] })}>
        {/* <Typography sx={{ mt: 1 }}>{resQuery?.data?.data?.name}</Typography> */}
      </HeaderBreadcrumbs>
      {resQuery.isLoading || resTravel?.isLoading ? (
        <WaitingBox />
      ) : (
        <RegistrantForm
          // isHistory
          {...{
            // isLoading,
            // onSubmitting: mutateAsync,
            data: {
              form: resQuery?.data?.data,
              team: resQuery?.data?.data?.team,
              members: resQuery?.data?.data?.members,
            },
            travelId: resQuery?.data?.data?.travel?.id,
            handleSuccess: onSuccessMutating,
          }}
        />
      )}
    </Box>
  );
};

export default Add;
