import { useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Typography, Alert } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import SelectorComponent from 'src/components/hook-form/Selector';
import api from '../../../../../services/api';
import axiosInstance from '../../../../../utils/axios';
import Enum from '../../../enum';
import { useQueryCustom } from '../../../../../utils/reactQueryHooks';
import Page from '../../../../../components/Page';
import HeaderBreadcrumbs from '../../../../../components/HeaderBreadcrumbs';
import useSettings from '../../../../../hooks/useSettings';
import WaitingBox from '../../../../../components/WaitingBox/index';
import validation from './validation';
import Layout from '../../Layout';
import UserStatusInfo from './UserStatusInfo';
import RegistrantForm from 'src/pages/RegistrantForms/components/Form';

const Travel = () => {
  const ModalLayoutQuestionInputRef = useRef();
  const queryParams = useParams();

  // const { travel } = queryParams;
  const { themeStretch } = useSettings();
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState();
  const backUrl = `${Enum.routes.root}`;
  const paramId = queryParams?.id;
  const currentBase = `${backUrl}/show/${paramId}/${Enum.enumTab.object.travels.value}`;
  // const ModalSectionFormRef = useRef();

  const navigate = useNavigate();

  // ------------------------------------------------------------------------------ modal toggle

  // ------------------------------------------------------------------------------ Mutation service
  // ----------------------------------------------------------------------------- SERVICE
  const getById = async () => axiosInstance.get(`${api.travelRegister.register.base}/${queryParams.course}`);

  const resQuery = useQueryCustom({
    name: `${api.travelRegister.register.base}_get_${queryParams.course}`,
    url: getById,
    params: { id: queryParams.course, client_id: queryParams.id },
    onSuccess: (resData) => {
      console.log({ resData });
      const x = resData?.data?.form?.[0];
      if (x && !currentTab) setCurrentTab({ label: x?.name, value: x?.id, data: x });
    },
  });
  // ------------------------------------------------------------------------------ submit form

  // // const creating = (params) => axiosInstance.post(api.travelRegister.base, params);
  // const updating = (params) =>
  //   axiosInstance.post(`${api.travelRegister.base}/${resQuery?.data?.data?.travel_register?.id}`, params);

  // const { isLoading, mutateAsync } = useMutationCustom({
  //   url: updating,
  //   name: `${api.public.travelRegister.base}_add`,
  //   // invalidQuery: `${Enum?.api?.base}_get_${id}_${travel}`,
  //   onSuccess: onSuccessMutating,
  //   // onError: onErrorMutating,
  // });
  // ------------------------------------------------------------------------------ submit form

  // const sectionsQuery = useQueryCustom({
  //   name: `${api.section.base}_get_${id}`,
  //   url: getting,
  // });

  const tab = resQuery?.data?.data?.form?.map((x) => ({ label: x?.name, value: x?.id, data: x })) || [];

  tab.push({
    label: 'وضعیت کاربر',
    value: 'userStatus',
  });
  console.log({ currentTab, resQuery, tab });

  return (
    <Layout>
      <Box
        sx={
          {
            // py:3
          }
        }
      >
        {resQuery.isLoading ? (
          <WaitingBox />
        ) : resQuery.isError ? (
          ''
        ) : (
          <Page
            sx={{
              py: 3,
            }}
            // title={resQuery?.data?.data?.name}
          >
            <HeaderBreadcrumbs heading={resQuery?.data?.data?.form?.[0]?.travel?.name} back={`${currentBase}/`} />
            <Box
              sx={{
                // display: 'flex',
                alignItems: 'center',
                gap: 2,
                display: { xs: 'none', md: 'flex' },
              }}
            >
              {tab?.map((x) => {
                return (
                  // <Link to={`${currentBase}/${queryParams.course}?tab=${x.value}`}>
                  <Typography
                    onClick={() => setCurrentTab(x)}
                    sx={{
                      color: 'grey.800',
                      p: 1,
                      borderBottom: '2px solid transparent',
                      fontWeight: '300',
                      fontSize: '15px',
                      cursor: 'pointer',
                      ...(currentTab?.value == x.value
                        ? {
                            borderColor: 'grey.800',
                            fontWeight: '900',
                          }
                        : {
                            '&:hover': {
                              color: 'grey.600',
                            },
                          }),
                    }}
                  >
                    {x.label}
                  </Typography>
                  // </Link>
                );
              })}
              {/* <Typography
                    onClick={() => setCurrentTab(x)}
                    sx={{
                      color: 'grey.800',
                      p: 1,
                      borderBottom: '2px solid transparent',
                      fontWeight: '300',
                      fontSize: '15px',
                      cursor: 'pointer',
                      ...(+currentTab?.value === +x.value
                        ? {
                            borderColor: 'grey.800',
                            fontWeight: '900',
                          }
                        : {
                            '&:hover': {
                              color: 'grey.600',
                            },
                          }),
                    }}
                  >
                  {"وضعیت کاربر"}  
                  </Typography> */}
            </Box>
            <Box
              sx={{
                alignItems: 'center',
                gap: 2,
                display: { md: 'none', xs: 'flex' },
              }}
            >
              <SelectorComponent
                sx={{
                  width: '200px',
                }}
                options={tab}
                onChange={(x) => {
                  console.log({ x });
                  setCurrentTab(x);
                  // navigate(`${currentBase}/${x.value}`);
                }}
                value={currentTab}
                isClearable={false}
              />
            </Box>
            <Box>
              {
                // resQuery.isFetching
                false ? (
                  <Box
                    sx={{
                      mt: 4,
                    }}
                  >
                    <WaitingBox />
                  </Box>
                ) : currentTab?.value === 'userStatus' ? (
                  <UserStatusInfo data={resQuery?.data?.data?.travel_register} />
                ) : currentTab?.data?.sections?.length ? (
                  <RegistrantForm
                    isHistory
                    {...{
                      // isLoading,
                      // onSubmitting: mutateAsync,
                      data: {
                        form: currentTab?.data,
                        team: resQuery?.data?.data?.team,
                        members: resQuery?.data?.data?.members,
                      },
                      travelId: resQuery?.data?.data?.travel_register?.id,
                      clientId: resQuery?.data?.data?.travel_register?.client?.id,
                    }}
                  />
                ) : (
                  // <SingleForm {...{ data: { form: currentTab?.data, team: resQuery?.data?.data?.team } }} />
                  <Box
                    sx={{
                      mt: 4,
                    }}
                  >
                    <Alert severity="info">{'موردی یافت نشد!'}</Alert>
                  </Box>
                )
              }
            </Box>
            {/* {resQuery?.data?.data?.form?.map((x, i) => (
              <Box>
                <SingleForm {...{ data: { form: x, team: resQuery?.data?.data?.team }, index: i }} />
              </Box>
            ))} */}
          </Page>
        )}
      </Box>
    </Layout>
  );
};

export default Travel;
