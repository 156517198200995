import { useTranslation } from 'react-i18next';
import { Cancel, ControlPoint, Edit } from '@mui/icons-material';
import { Box, Button, Grid, Modal, Typography, Alert } from '@mui/material';
import { useRef, useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useFormContext, Controller } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

// import { formTypesObject } from '../../../enumeration';
import EPublicForm from '../enum';
import InputsCore from 'src/components/InputsCore';
import { formTypesObject } from 'src/enumeration';

const SectionPublic = ({ data, loading, disabled, isHistory, members, isFirst, refetch }) => {
  const urlParams = useParams();
  const { t } = useTranslation();

  const { control, watch, setValue, getValues, reset } = useFormContext();

  console.log('* * * SectionPublic : ', { data }, data?.label, data?.isCompanions);
  const watchParent = data?.isCompanions && watch(String(data?.parentId));
  if (data?.isCompanions) console.log('* * * SectionPublic : ', { watchParent, data }, data?.label);
  const newMembers = useMemo(() => {
    if (members > 0) return new Array(members || 0).fill({})?.map((x) => ({ id: uuidv4() }));
  }, [members]);
  useEffect(() => {
    try {
      if (watchParent >= 0 && data?.parentId) {
        const values = getValues();
        const current = (values[data?.id] ? [...values[data?.id]] : [])?.map((x) => ({ ...x, id: x.id || uuidv4() }));
        console.log(
          '* * * SectionPublic useEffect: ',
          { watchParent, values, current, parentId: data?.parentId },
          data?.label
        );

        if (current?.length > watchParent) {
          const newValue = current.slice(0, -(current?.length - watchParent));
          console.log(
            '* * * SectionPublic useEffect: ',
            { newValue },
            -(current?.length - watchParent),
            String(data?.id)
          );
          setValue(String(data?.id), newValue);
        } else {
          const appendedCount = watchParent - current?.length;
          if (appendedCount <= 0 || !appendedCount) return;
          console.log('* * * SectionPublic useEffect: ', { current, appendedCount });
          new Array(appendedCount || 0).fill({})?.map(() => {
            current.push({ id: uuidv4() });
          });
          setValue(String(data?.id), current);
        }
      }
      // console.log("* * * SectionPublic useEffect: ",{watchParent,values,current,parentId:data?.parentId},data?.label);
    } catch (error) {
      console.log('* * * SectionPublic useEffect: ', { error });
    }
  }, [watchParent]);

  const onRemoveCompanion = (index) => {
    console.log('* * * SectionPublic onRemoveCompanion', { index, data });
    const values = getValues();
    let current = [...values[data?.id]];
    console.log('* * * SectionPublic onRemoveCompanion', { values, index, data, current });
    current = current?.filter((_, i) => i != index);
    console.log('* * * SectionPublic onRemoveCompanion', { index, data, current });
    setValue(String(data?.id), current);
    setValue(String(data?.parentId), watchParent - 1 || 0);
  };

  return (data?.isCompanions ? watchParent > 0 || members > 0 : true) ? (
    <Controller
      name={data?.id?.toString()}
      control={control}
      render={({ field, fieldState: { error } }) => {
        if (data?.isCompanions) console.log({ field });
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                p: 2,
                backgroundColor: 'grey.main',
                borderRadius: '8px',
              }}
            >
              <Typography>{data?.label}</Typography>
            </Box>

            {data?.isCompanions ? (
              // true?"":
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 3,
                }}
              >
                {
                  // new Array(+watchParent || 0)
                  //   .fill({})
                  // watchCompanion
                  (newMembers || field.value)?.map((x, i) => {
                    return (
                      <Box
                        key={x.id}
                        sx={{
                          p: 3,
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 2,
                        }}
                      >
                        <Grid
                          container
                          sx={{
                            // mb:2,
                            // backgroundColor: 'grey.main',
                            p: 0,
                          }}
                          spacing={3}
                        >
                          <Grid xs={12} item>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                p: 2,
                                backgroundColor: 'grey.main',
                                borderRadius: '8px',
                              }}
                            >
                              <Typography>{`همراه ${i + 1}`}</Typography>

                              {data?.isCompanions && isFirst ? (
                                <Cancel
                                  sx={{
                                    cursor: 'pointer',
                                    color: 'grey.500',
                                    '&:hover': {
                                      color: 'grey.700',
                                    },
                                  }}
                                  onClick={() => onRemoveCompanion(i)}
                                />
                              ) : (
                                ''
                              )}
                            </Box>
                          </Grid>

                          {data?.inputs?.map((x) => {
                            let props = {
                              xs: 12,
                              md: 6,
                            };

                            if ([formTypesObject.IMAGE.value, formTypesObject.CHECKBOX.value].includes(x.type)) {
                              props = {
                                ...props,
                                xs: 12,
                                md: 12,
                              };
                            }
                            if (formTypesObject.LOCATION.value === x.type && x?.options?.isAddress) {
                              props = {
                                ...props,
                                xs: 12,
                                md: 12,
                              };
                            }

                            return (
                              <Grid key={`${data?.id}.${i}.${x.id}`} item {...props}>
                                <InputsCore
                                  data={x}
                                  name={`${data?.id}.${i}.${EPublicForm.bseName || ''}${x?.id}`}
                                  loading={loading}
                                  sectionId={data?.id}
                                  disabled={disabled}
                                  isHistory={isHistory}
                                  index={i}
                                  refetch={refetch}
                                />
                              </Grid>
                            );
                          })}
                        </Grid>
                      </Box>
                    );
                  })
                }
              </Box>
            ) : (
              <Grid sx={{}} container spacing={3}>
                {data?.inputs?.length ? (
                  data?.inputs?.map((x) => {
                    let props = {
                      xs: 12,
                      md: 6,
                    };
                    
                    if(x?.type !== "SELECT" && x?.type !== "DATE" && x?.type !== "RADIO" && x?.type !== "LOCATION") return 
                    if(x?.options?.isAddress) return 

                    if ([formTypesObject.IMAGE.value, formTypesObject.CHECKBOX.value].includes(x.type)) {
                      props = {
                        xs: 12,
                        md: 12,
                      };
                    }
                    if (formTypesObject.LOCATION.value === x.type && x?.options?.isAddress) {
                      props = {
                        xs: 12,
                        md: 12,
                      };
                    }

                    return (
                      <Grid key={`${data?.id}.${x.id}`} item {...props}>
                        <InputsCore
                          data={x}
                          // name={`name.firstname`}
                          name={`${data?.id}.${EPublicForm.bseName || ''}${x?.id}`}
                          loading={loading}
                          sectionId={data?.id}
                          disabled={disabled}
                          isHistory={isHistory}
                          refetch={refetch}
                        />
                      </Grid>
                    );
                  })
                ) : (
                  <Grid item xs={12}>
                    <Alert severity="info">{'موردی یافت نشد!'}</Alert>
                  </Grid>
                )}
              </Grid>
            )}
          </Box>
        );
      }}
    />
  ) : (
    <></>
  );
};

export default SectionPublic;
