import { InputAdornment } from '@mui/material';
import RHFTextField from '../../../../components/hook-form/RHFTextField';
import AcceptOrRejectWithQuestionsWithReason from '../AcceptOrRejectWithQuestionsWithReason';

const FormInputString = ({ name, data, disabled, index, refetch, ...props }) => {
  return (
    <RHFTextField
      name={name}
      label={data?.label}
      disabled={disabled}
      required={data?.options?.required}
      InputProps={{
        autoComplete: 'off',
        ...(data?.need_confirm // && isHistory
          ? {
              endAdornment: (
                <InputAdornment
                  position="start"
                  sx={{
                    display: 'flex',
                    gap: 1,
                  }}
                >
                  <AcceptOrRejectWithQuestionsWithReason
                    {...data?.client_inputs?.[index || 0]}
                    history={data?.old_client_inputs?.[index || 0]}
                    type={data?.type}
                    refetch={refetch}
                  />
                </InputAdornment>
              ),
            }
          : {}),
      }}
      {...props}
      {...data?.options?.inputProps}
    />
  );
};

export default FormInputString;
