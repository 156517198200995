import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { Box, Grid, InputAdornment, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import RHFTextField from '../../../../../components/hook-form/RHFTextField';
import ProvinceSelector from '../../../../../components/selector/Province';
import AcceptOrRejectWithQuestionsWithReason from '../../AcceptOrRejectWithQuestionsWithReason';

const FormInputLocationProvince = ({ name, data, isHistory, index, refetch, ...props }) => {
  const { setValue } = useFormContext();

  const childCityName = index >= 0 ? data?.childCityNames?.[index] : data?.childCityName;

  const handleChange = (a, v) => {
    // console.log('* * * FormInputLocationProvince handleChange : ', { a, v });
    if (childCityName) setValue(childCityName, undefined);
  };
  console.log('* * * FormInputLocationProvince : ', { childCityName, index, data });

  return (
    <Box>
      <ProvinceSelector.Element
        geById={data?.client_inputs?.[index || 0]?.value}
        required={data?.options?.required}
        name={name}
        label={data?.label}
        onChange={handleChange}
        InputProps={
          data?.need_confirm
            ? // && isHistory
              {
                endAdornment: (
                  <InputAdornment position="start">
                    <AcceptOrRejectWithQuestionsWithReason
                      {...data?.client_inputs?.[index || 0]}
                      history={data?.old_client_inputs?.[index || 0]}
                      type={data?.type}
                      refetch={refetch}
                    />
                  </InputAdornment>
                ),
              }
            : ''
        }
        {...props}
      />
    </Box>
  );
};

export default FormInputLocationProvince;
