import { travelTypeObject } from 'src/enumeration';
import buildAccess from 'src/permission/buildAccess';
import { PATH_DASHBOARD } from 'src/routes/paths';

const buildSideSubitems = (key, id, records) => [
  // {
  //   title: 'لیست ثبت نام ها',
  //   path: records
  //     ? `${PATH_DASHBOARD.records.visitorRegistrations(key?.value, true, id)}`
  //     : `${PATH_DASHBOARD.visitorRegistrations.root(key?.value, id)}`,
  //   // permission: buildAccess(PATH_DASHBOARD.visitorRegistrations.name, key?.type)?.read,
  // },
  {
    title: 'مدیریت زائرین',
    path: records
      ? `${PATH_DASHBOARD.records.visitorManagement(key?.value, true, id)}`
      : `${PATH_DASHBOARD.visitorManagement.root(key?.value, id)}/registrations`,
    permission: buildAccess(PATH_DASHBOARD.visitorManagement.name, key?.type)?.read,
  },
  {
    title: 'مدیریت نظام وظیفه',
    path: records
      ? `${PATH_DASHBOARD.records.dutySystemManagement(key?.value, true, id)}`
      : `${PATH_DASHBOARD.dutySystemManagement.root(key?.value, id)}`,
    permission: buildAccess(PATH_DASHBOARD.dutySystemManagement.name, key?.type)?.read,
  },
  {
    title: 'مدیریت امور بانکی',
    path: records
      ? `${PATH_DASHBOARD.records.bankSystemManagement(key?.value, true, id)}`
      : `${PATH_DASHBOARD.bankSystemManagement.root(key?.value, id)}`,
    permission: buildAccess(PATH_DASHBOARD.bankSystemManagement.name, key?.type)?.read,
  },
  {
    title: 'مدیریت کاروان ها',
    path: records
      ? `${PATH_DASHBOARD.records.caravansManagement(key?.value, true, id)}`
      : `${PATH_DASHBOARD.caravansManagement.root(key?.value, id)}`,
    permission: buildAccess(PATH_DASHBOARD.caravansManagement.name, key?.type)?.read,
  },
  {
    title: 'فرم ها',
    path: records
      ? `${PATH_DASHBOARD.records.forms(key?.value, true, id)}`
      : `${PATH_DASHBOARD.forms.root(key?.value, id)}`,
    permission: buildAccess(PATH_DASHBOARD.forms.name, travelTypeObject[key]?.type)?.read,
  },
];

export default buildSideSubitems;
