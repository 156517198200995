import { InputAdornment } from '@mui/material';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import RHFRadioGroups from '../hook-form/RHFRadioGroups';
import AcceptOrRejectWithQuestionsWithReason from '../AcceptOrRejectWithQuestionsWithReason';

const FormInputRadio = ({ name, data, disabled, index, isHistory }) => {
  const { control, watch, setValue } = useFormContext();

  let watchParent;
  if (data?.options?.parentId) watchParent = watch(`${data?.options?.parentId}`);

  const options = useMemo(() => {
    return {
      array: data?.options?.items?.map((x) => ({ label: x.label, value: x.label })),
      data: data?.options,
    };
  }, [data]);

  const onChange = (e) => {
    // const value= e.target.value
    const afterChange = data?.options?.afterChange;
    if (afterChange) {
      const childs = Object.values(data?.options?.childs || {});
      if (afterChange.by === 'parent' && childs?.length) {
        const is = true;
        if (is) {
          if (afterChange.type === 'resetChilds') {
            // console.log("* * * FormInputRadio resetChilds" ,);
            childs?.map((x) => setValue(x?.name, null));
          }
        }
      }
    }
  };

  // console.log({ options, data }, data?.label, '* * * FormInputRadio');

  return (
    <RHFRadioGroups
      options={options?.array || []}
      name={name}
      label={data?.label}
      disabled={disabled}
      onChange={onChange}
      required={data?.options?.required}
      InputProps={
        data?.need_confirm //&& isHistory
          ? {
              endAdornment: (
                <InputAdornment
                  position="start"
                  sx={{
                    display: 'flex',
                    gap: 1,
                  }}
                >
                  <AcceptOrRejectWithQuestionsWithReason {...data?.client_inputs?.[index || 0]} />
                </InputAdornment>
              ),
            }
          : ''
      }
    />
  );
};

export default FormInputRadio;
