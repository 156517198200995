import { InputAdornment } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import RHFDatePicker from '../../../../components/hook-form/RHFDatePicker';
import AcceptOrRejectWithQuestionsWithReason from '../AcceptOrRejectWithQuestionsWithReason';

const FormInputDate = ({ name, data, disabled, index, refetch, ...props }) => {
  const [hidden, setHidden] = useState(false);

  const { control, watch, setValue } = useFormContext();

  let watchParent;
  if (data?.options?.parentId) watchParent = watch(`${data?.options?.parentId}`);
  // console.log("* * * FormInputLocationCity : ",{name,data,watchProvince},`${data?.parentProvinceName}`);

  const options = useMemo(() => {
    return {
      array: data?.options?.items?.map((x) => ({ label: x.label, value: x.label })),
      data: data?.options,
      selector: data?.options?.selectType?.selector,
    };
  }, [data]);

  useEffect(() => {
    if (!watchParent) return;
    const afterChangeParent = data?.options?.afterChangeParent;
    if (afterChangeParent) {
      const is = watchParent?.value === afterChangeParent.on;
      if (afterChangeParent.by === 'child') {
        if (is) {
          if (afterChangeParent.type === 'resetChilds') setValue(name, null);
          if (data?.options?.afterChangeParent?.withHidden && !hidden) setHidden(true);
        } else if (data?.options?.afterChangeParent?.withHidden && hidden) setHidden(false);
      }
    }
  }, [watchParent]);

  console.log({ options, watchParent, data }, data?.label, '* * * FormInputSelect');
  if (hidden) return <></>;

  return (
    <RHFDatePicker
      name={name}
      label={data?.label}
      disabled={disabled}
      required={data?.options?.required}
      inputProps={{
        autoComplete: 'off',
        ...(data?.need_confirm
          ? //  && isHistory
            {
              endAdornment: (
                <InputAdornment
                  position="start"
                  sx={{
                    display: 'flex',
                    gap: 1,
                  }}
                >
                  <AcceptOrRejectWithQuestionsWithReason
                    {...data?.client_inputs?.[index || 0]}
                    history={data?.old_client_inputs?.[index || 0]}
                    type={data?.type}
                    refetch={refetch}
                  />
                </InputAdornment>
              ),
            }
          : {}),
      }}
      pickerProps={data?.options?.inputProps}
      {...props}
    />
  );
};

export default FormInputDate;
