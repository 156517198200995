import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { Box, Grid, InputAdornment, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import RHFTextField from '../../hook-form/RHFTextField';
import ProvinceSelector from '../../selector/Province';
import AcceptOrRejectWithQuestionsWithReason from '../../AcceptOrRejectWithQuestionsWithReason';

const FormInputLocationProvince = ({ name, data, index }) => {
  const { setValue } = useFormContext();

  const childCityName = index >= 0 ? data?.childCityNames?.[index] : data?.childCityName;

  const handleChange = (a, v) => {
    // console.log('* * * FormInputLocationProvince handleChange : ', { a, v });
    if (childCityName) setValue(childCityName, undefined);
  };
  console.log('* * * FormInputLocationProvince : ', { childCityName, index, data });

  return (
    <Box>
      <ProvinceSelector.Element
        geById={data?.client_inputs?.[index || 0]?.value}
        name={name}
        label={data?.label}
        onChange={handleChange}
        required={data?.options?.required}
        InputProps={
          data?.need_confirm
            ? // && isHistory
              {
                endAdornment: (
                  <InputAdornment position="start">
                    <AcceptOrRejectWithQuestionsWithReason {...data?.client_inputs?.[index || 0]} />
                  </InputAdornment>
                ),
              }
            : ''
        }
      />
    </Box>
  );
};

export default FormInputLocationProvince;
